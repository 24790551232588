import React from 'react';



function About() {
  return (
    <div>
      <h1>Photos</h1>
      <p>We are passionate about serving your needs to make your occasions Bright and Beauty</p>

      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0001.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0002.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0003.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0004.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0005.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0006.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0007.jpg" alt="Flower 4" />
        </div>
      </div>
      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0008.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0009.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0010.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0011.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0012.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0013.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0014.jpg" alt="Flower 4" />
        </div>
      </div>

      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0015.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0016.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0017.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0018.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0019.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0020.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0021.jpg" alt="Flower 4" />
        </div>
      </div>

      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0022.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0023.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0024.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0025.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0026.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0027.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0028.jpg" alt="Flower 4" />
        </div>
      </div>


      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0029.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0030.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0031.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0032.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0033.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0034.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0035.jpg" alt="Flower 4" />
        </div>
      </div>


      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0036.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0037.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0038.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0039.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0040.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0041.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0042.jpg" alt="Flower 4" />
        </div>
      </div>


      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0043.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0044.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0045.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0046.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0047.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0048.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0049.jpg" alt="Flower 4" />
        </div>
      </div>

      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0050.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0051.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0052.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0053.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0054.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0055.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0056.jpg" alt="Flower 4" />
        </div>
      </div>


      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0057.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0058.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0059.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0060.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0061.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0062.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0063.jpg" alt="Flower 4" />
        </div>


</div>
        <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0064.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0065.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0066.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0067.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0068.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0069.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0070.jpg" alt="Flower 4" />
        </div>
      </div>

      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0071.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0072.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0073.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0074.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0075.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0076.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0077.jpg" alt="Flower 4" />
        </div>
      </div>

      <div className="image-gallery">
      <div className="image-item">
          <img src="/images/IMG-20250106-WA0078.jpg" alt="Flower 1" />
    
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0079.jpg" alt="Flower 2" />
       
        </div>
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0080.jpg" alt="Flower 3" />
       
        </div>
      
        <div className="image-item">
          <img src="/images/IMG-20250106-WA0081.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0082.jpg" alt="Flower 4" />
        
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0083.jpg" alt="Flower 4" />
          
          </div>
          <div className="image-item">
          <img src="/images/IMG-20250106-WA0084.jpg" alt="Flower 4" />
        </div>
      </div>




    </div>


    
  );
}

export default About;
