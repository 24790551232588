import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Shop from './pages/Shop';
import Contact from './pages/Contact';
import './App.css';

function App() {
  return (
    <Router>
      <header className="header">
      <div className="logo-container">
        <img src="/images/rajam-logo.jpeg" type="img" alt="Logo" className="logo" />

</div>

<nav className="nav-bar">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </header>
      <div className="App">
     

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<About />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
